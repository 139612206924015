// 基础路由映射
const routes = [
  {
    path: "/",
    component: () => import("@/views/bondot"),
    redirect: "/home",
    children: [],
  },

  {
    // bondot官网
    name: "bondot",
    path: "/home",
    component: () => import("@/views/bondot"),
    meta: { noAuth: true },
  },
  {
    // bondot官网
    name: "aboutUs",
    path: "/aboutUs",
    component: () => import("@/views/bondot/aboutUs"),
    meta: { noAuth: true },
  },

  {
    name: "404",
    path: "/:catchAll(.*)",
    component: () => import("@/views/404"),
    meta: { noAuth: true },
  },
];
export default routes;
